import { Button, Form, Input, Spin, message } from "antd";
import React, { useState } from "react";
import { RuleObject } from "rc-field-form/lib/interface";
import { UserAPI } from "services/user-service";
import PageContainer from "pages/container/PageContainer";
import Title from "components/Page/Title";

interface FormValues {
  username: string;
  password: string;
}

function UserManagement() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const checkUserName: RuleObject["validator"] = async (_, username) => {
    if (!username) {
      return Promise.resolve();
    }

    try {
      const response = await UserAPI.checkUserExist(username);
      if (response.data.result) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error("Username not found"));
      }
    } catch (err) {
      console.error(err);
      return Promise.reject(new Error("Validation failed. Try again later."));
    }
  };

  const onFinish = async ({ username, password }: FormValues) => {
    try {
      setLoading(true);
      await UserAPI.changePassword(username, password);
      message.success("เปลี่ยนรหัสผ่านสำเร็จ");
      form.resetFields(["password"]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer className="user-management-container">
      <Title level={4}>จัดการ user</Title>

      <div className="pt-4 w-[380px]">
        <Spin spinning={loading}>
          <Form form={form} onFinish={onFinish} labelCol={{ span: 7 }}>
            <Form.Item
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  message: "กรุณาระบุ username",
                },
                { validator: checkUserName },
              ]}
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "กรุณาระบุ password",
                },
                { min: 8, message: "** รหัสผ่านขั้นต่ำ 8 ตัวอักษร" },
              ]}
            >
              <Input allowClear />
            </Form.Item>
            <div>
              <Button type="primary" htmlType="submit" className="ml-[112px]">
                เปลี่ยนรหัสผ่าน
              </Button>
            </div>
          </Form>
        </Spin>
      </div>
    </PageContainer>
  );
}

export default UserManagement;
