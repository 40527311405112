import {
  FetchAccountsResponse,
  FetchTransactionsRequest,
  FetchTransactionsResponse,
  GetAutoWithdrawAccountResponse,
  GetKbankTransDetailRequest,
  GetKbankTransDetailResponse,
  GetWithdrawConfigResponse,
  IAutoWithdrawAccount,
  IWithdrawConfig,
  InquiryAccountDetailRequest,
  InquiryAccountDetailResponse,
  TransferHistoryRequest,
  TransferHistoryResponse,
  TransferRequest,
} from "types/account";
import { GetBankListResponse } from "types/bank";
import { AxiosAPI } from "./axios-service-creator";
import { CancelToken } from "axios";

export const AccountManagementService = {
  fetchAccounts: () => AxiosAPI.get<FetchAccountsResponse>("/accounts"),
  fetchTransactions: (params: FetchTransactionsRequest) =>
    AxiosAPI.get<FetchTransactionsResponse>("/accounts/info", { params }),
  getKbankTransDetail: (params: GetKbankTransDetailRequest) =>
    AxiosAPI.get<GetKbankTransDetailResponse>("/accounts/info/kbank/detail", { params }),
  inquiryAccountDetail: (payload: InquiryAccountDetailRequest, signal?: AbortSignal) =>
    AxiosAPI.post<InquiryAccountDetailResponse>("/accounts/transfer/inquiry", payload, { signal }),
  transfer: (payload: TransferRequest) => AxiosAPI.post("/accounts/transfer/confirm", payload),
  fetchTransferHistories: (params: TransferHistoryRequest, signal?: AbortSignal) =>
    AxiosAPI.get<TransferHistoryResponse>("/accounts/transfer/history", { params, signal }),
  getBankList: () => AxiosAPI.get<GetBankListResponse>("/accounts/banks"),
  getAutoWithdrawAccounts: (cancelToken?: CancelToken) =>
    AxiosAPI.get<GetAutoWithdrawAccountResponse>("/auto-withdraw", { cancelToken }),
  saveAutoWithdrawAccounts: (payload: IAutoWithdrawAccount) =>
    AxiosAPI.post<GetAutoWithdrawAccountResponse>("/auto-withdraw", payload),
  getWithdrawConfig: (cancelToken?: CancelToken) =>
    AxiosAPI.get<GetWithdrawConfigResponse>("/withdraw-config", { cancelToken }),
  saveWithdrawConfig: (payload: IWithdrawConfig) =>
    AxiosAPI.post<GetWithdrawConfigResponse>("/withdraw-config", payload),
};
