import React, { memo, useState, useEffect } from "react";
import { UnorderedListOutlined } from "@ant-design/icons";
import { Button, Space, Table, Tooltip } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import TagAccountStatus from "components/Tag/TagAccountStatus";
import { AccountColor, AccountType, AccountTypeDesc } from "const";
import { FaCheckCircle } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import { IAccount, IAccountStatus, IAccountType } from "types/account";
import { AiFillSetting } from "react-icons/ai";
import DrawerAutoWithdrawSetup from "./DrawerAutoWithdrawSetup";

type AccountTableProps = TableProps<IAccount> & {
  onViewStatement: (record: IAccount) => void;
};

function AccountTable({ onViewStatement, ...props }: AccountTableProps) {
  const [withdrawAccounts, setWithdrawAccounts] = useState<IAccount[]>([]);
  const [openAutoWithdrawAccount, setOpenAutoWithdrawAccount] = useState(false);

  useEffect(() => {
    if (props.dataSource) {
      setWithdrawAccounts(props.dataSource.filter((account) => account.type === AccountType.Withdraw));
    }
  }, [props.dataSource]);

  const columns: ColumnsType<IAccount> = [
    {
      title: "#",
      key: "__no__",
      width: 50,
      align: "center",
      render: (_, __, index: number) => index + 1,
    },
    {
      title: "ชื่อบัญชี",
      dataIndex: "accountName",
    },
    {
      title: "เลขบัญชี",
      dataIndex: "accountNo",
    },
    {
      title: "ธนาคาร",
      dataIndex: "bank",
      align: "center",
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      align: "center",
      render: (type: IAccountType) => <span style={{ color: AccountColor[type] }}>{AccountTypeDesc[type]}</span>,
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      align: "center",
      width: 150,
      render: (status: IAccountStatus) => <TagAccountStatus className="w-[80px]" status={status} />,
    },
    {
      title: "online service",
      dataIndex: "serviceOnline",
      align: "center",
      width: 150,
      render: (serviceOnline: boolean) => (
        <div title={serviceOnline ? "เชื่อมต่อได้ปกติ" : "ไม่สามารถเชื่อมต่อได้"} className="flex justify-center">
          {serviceOnline ? (
            <FaCheckCircle size={20} color="#87d068" />
          ) : (
            <RiCloseCircleFill size={20} color="#50535c" />
          )}
        </div>
      ),
    },
    {
      key: "__action__",
      align: "center",
      width: 200,
      render: (record: IAccount) => {
        return (
          <Space>
            <Button
              type="primary"
              icon={<UnorderedListOutlined />}
              onClick={() => onViewStatement(record)}
              size="small"
            >
              รายการฝากถอน
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <div className="text-right mb-2">
        <Tooltip title="Setting Auto Withdraw">
          <Button type="primary" onClick={() => setOpenAutoWithdrawAccount(true)}>
            <Space>
              <AiFillSetting size={16} />
              ตั้งค่าบัญชีถอนออโต้
            </Space>
          </Button>
        </Tooltip>
      </div>
      <Table
        rowKey="accountId"
        className="c-table header-center"
        columns={columns}
        scroll={{
          x: 1246,
        }}
        bordered
        size="small"
        {...props}
      />

      <DrawerAutoWithdrawSetup
        withdrawAccounts={withdrawAccounts}
        open={openAutoWithdrawAccount}
        onCancel={() => setOpenAutoWithdrawAccount(false)}
      />
    </div>
  );
}

export default memo(AccountTable);
