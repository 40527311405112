import Back from "components/Misc/Back";
import Title from "components/Page/Title";
import PageContainer from "pages/container/PageContainer";
import React, { memo, useEffect, useCallback } from "react";
import { AccountManagementService } from "services/account-management-service";
import { IAccount } from "types/account";
import { alertError } from "utils/error-util";
import AccountBreadcrumb from "./AccountBreadcrumb";
import AccountTable from "./AccountTable";
import Statements from "./Statements";
import {
  useAccounts,
  useLoading,
  useNextPageLevel,
  usePageLevel,
  usePrevPageLevel,
  useResetState,
  useSetAccounts,
  useSetLoading,
  useViewAccount,
} from "./store";

function AccountManagementPage() {
  const loading = useLoading();
  const accounts = useAccounts();
  const pageLevel = usePageLevel();
  const setAccounts = useSetAccounts();
  const setLoading = useSetLoading();
  const nextPageLevel = useNextPageLevel();
  const prevPageLevel = usePrevPageLevel();
  const viewAccount = useViewAccount();
  const resetState = useResetState();

  useEffect(() => {
    resetState();
  }, []);

  useEffect(() => {
    try {
      (async () => {
        setLoading(true);
        const response = await AccountManagementService.fetchAccounts();
        setAccounts(response.data.result);
      })();
    } catch (error) {
      alertError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const onViewStatement = useCallback((record: IAccount) => {
    viewAccount(record.accountId);
    nextPageLevel();
  }, []);

  const renderPageContent = () => {
    switch (pageLevel) {
      case 0:
        return <AccountTable loading={loading} dataSource={accounts} onViewStatement={onViewStatement} />;
      case 1:
        return <Statements />;
    }
  };

  return (
    <PageContainer className="account-management-page">
      <Title className="mb-0 flex gap-2" level={4}>
        {pageLevel > 0 && <Back onClick={() => prevPageLevel()} />}
        จัดการบัญชี
      </Title>
      <AccountBreadcrumb pages={pages} pageLevel={pageLevel} />
      <div className="mt-4">{renderPageContent()}</div>
    </PageContainer>
  );
}

export default memo(AccountManagementPage);

export const pages = {
  0: "บัญชี",
  1: "รายการฝากถอน",
} as const;

export declare type IPages = typeof pages;
export declare type PageLevel = keyof typeof pages;
