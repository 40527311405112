import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Col,
  Form,
  Grid,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TimePicker,
  message,
} from "antd";
import { IAccount, IAutoWithdrawAccount, IWithdrawConfig } from "types/account";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ColumnsType, TableProps } from "antd/es/table";
import { AccountManagementService } from "services/account-management-service";
import axios, { CancelTokenSource } from "axios";
import { formatNumber } from "utils/format-util";
import { RiEdit2Fill } from "react-icons/ri";
import styled from "styled-components";
import { AiFillSetting } from "react-icons/ai";
import { MdAddCircle } from "react-icons/md";

dayjs.extend(customParseFormat);

type DrawerAutoWithdrawSetupProps = {
  withdrawAccounts: IAccount[];
  open: boolean;
  onCancel: () => void;
};

const { RangePicker } = TimePicker;

function DrawerAutoWithdrawSetup({ withdrawAccounts, open, onCancel }: DrawerAutoWithdrawSetupProps) {
  const [scheduleForm] = Form.useForm();
  const [withdrawConfigForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editingScheduleAccountId, setEditingScheduleAccountId] = useState<string | null>();
  const [showScheduleForm, setShowScheduleForm] = useState(false);
  const [showWithdrawConfigForm, setShowWithdrawConfigForm] = useState(false);
  const [autoWithdrawAccountList, setAutoWithdrawAccount] = useState<IAutoWithdrawAccount[]>([]);
  const breakpoint = Grid.useBreakpoint();

  const fetchAutoWithdrawAccountList = useCallback(async (source?: CancelTokenSource) => {
    try {
      setLoading(true);
      const response = await AccountManagementService.getAutoWithdrawAccounts(source?.token);
      setAutoWithdrawAccount(
        response.data.result.sort((a, b) => {
          if (a.accountNo < b.accountNo) {
            return -1;
          }
          if (a.accountNo > b.accountNo) {
            return 1;
          }
          return 0;
        })
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        message.error("เกิดข้อผิดพลาดจากเซิฟเวอร์: " + error.message);
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchWithdrawAccountConfig = useCallback(async (source?: CancelTokenSource) => {
    try {
      setLoading(true);
      const response = await AccountManagementService.getWithdrawConfig(source?.token);
      withdrawConfigForm.setFieldsValue(response.data.result);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        message.error("เกิดข้อผิดพลาดจากเซิฟเวอร์: " + error.message);
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (open) {
      const source = axios.CancelToken.source();
      fetchAutoWithdrawAccountList(source);

      return () => {
        source.cancel("ยกเลิกการดึงข้อมูล");
      };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
  }, [open, fetchAutoWithdrawAccountList]);

  useEffect(() => {
    if (showWithdrawConfigForm) {
      const source = axios.CancelToken.source();
      fetchWithdrawAccountConfig(source);

      return () => {
        source.cancel("ยกเลิกการดึงข้อมูล");
      };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
  }, [showWithdrawConfigForm, fetchAutoWithdrawAccountList]);

  useEffect(() => {
    if (!open) {
      setShowScheduleForm(false);
      setEditingScheduleAccountId(null);
      scheduleForm.resetFields();
    }
  }, [open, scheduleForm]);

  const addNewScheduleConfig = () => {
    setEditingScheduleAccountId(null);
    setShowScheduleForm(true);
    scheduleForm.resetFields();
  };

  const editScheduleConfig = (account: IAutoWithdrawAccount) => {
    setEditingScheduleAccountId(account.id);
    setShowScheduleForm(true);
    scheduleForm.resetFields();
    scheduleForm.setFieldsValue({
      ...account,
      times: [dayjs(account.fromTime, "HH:mm"), dayjs(account.toTime, "HH:mm")],
      isEdit: true,
    });
  };

  const cancelScheduleForm = () => {
    setEditingScheduleAccountId(null);
    setShowScheduleForm(false);
  };

  const onSaveScheduleForm = async (account: IAutoWithdrawAccount) => {
    try {
      setLoading(true);
      await AccountManagementService.saveAutoWithdrawAccounts(account);
      message.success("บันทึกสำเร็จ");
      fetchAutoWithdrawAccountList();
      cancelScheduleForm();
    } catch (error) {
      message.error(error.message);
      console.error(error);
    }
  };

  const onScheduleFormChange = (changedValues: IAutoWithdrawAccount) => {
    if ("accountId" in changedValues) {
      const account = withdrawAccounts.find((account) => account.accountId === changedValues.accountId);
      if (account) {
        scheduleForm.setFieldsValue({
          accountNo: account.accountNo,
          accountName: account.accountName,
        });
      }
    }
  };

  const onScheduleFormFinish = async () => {
    try {
      await scheduleForm.validateFields();
      const values = scheduleForm.getFieldsValue(true);
      const payload = {
        ...values,
        fromTime: values.times[0].format("HH:mm"),
        toTime: values.times[1].format("HH:mm"),
      };
      onSaveScheduleForm(payload);
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveWithdrawConfigForm = async (config: IWithdrawConfig) => {
    try {
      setLoading(true);
      await AccountManagementService.saveWithdrawConfig(config);
      message.success("บันทึกสำเร็จ");
      cancelWithdrawConfigForm();
    } catch (error) {
      message.error(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onWithdrawConfigFormFinish = async () => {
    try {
      await withdrawConfigForm.validateFields();
      const values = withdrawConfigForm.getFieldsValue(true);
      const config = {
        id: values.id,
        turnOver: values.turnOver,
        withdrawAmountPerTime: values.withdrawAmountPerTime,
        withdrawTimePerDay: values.withdrawTimePerDay,
        limitWithdrawAmountPerDay: values.limitWithdrawAmountPerDay,
      };
      onSaveWithdrawConfigForm(config);
    } catch (error) {
      console.error(error);
    }
  };

  const cancelWithdrawConfigForm = () => {
    withdrawConfigForm.resetFields();
    setShowWithdrawConfigForm(false);
  };

  const columns: ColumnsType<IAutoWithdrawAccount> = [
    {
      title: <b>ตารางเวลาการใช้งานบัญชี</b>,
      key: "__header__",
      align: "center",
      children: [
        {
          title: "#",
          key: "__no__",
          width: 50,
          align: "center",
          render: (_, __, index: number) => index + 1,
        },
        {
          title: "ชื่อบัญชี",
          dataIndex: "accountName",
        },
        {
          title: "เลขบัญชี",
          dataIndex: "accountNo",
        },
        {
          title: "เวลา",
          key: "__time__",
          align: "center",
          render: (record: IAutoWithdrawAccount) => `${record.fromTime} - ${record.toTime}`,
        },
        {
          title: "จำนวนเงิน <=",
          dataIndex: "depositLessThanEqualAmount",
          align: "right",
          render: (depositLessThanEqualAmount) => formatNumber(depositLessThanEqualAmount),
        },
        {
          key: "__action__",
          align: "center",
          width: 80,
          render: (record: IAutoWithdrawAccount) => {
            return (
              <Button
                className="w-10 icon-tf-3"
                type="primary"
                icon={<RiEdit2Fill size={16} />}
                onClick={() => editScheduleConfig(record)}
                size="small"
              />
            );
          },
        },
      ],
    },
  ];

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={<b className="text-lg">ตั้งค่าบัญชีถอนออโต้</b>}
      footer={null}
      width={!breakpoint.lg ? "auto" : 860}
    >
      <Spin spinning={loading}>
        <div className="text-right mb-3">
          <Space>
            <Button
              onClick={() => {
                setShowWithdrawConfigForm(true);
                cancelScheduleForm();
              }}
              type="primary"
              ghost
            >
              <Space>
                <AiFillSetting size={16} />
                ตั้งค่าการถอน
              </Space>
            </Button>
            <Button
              onClick={() => {
                addNewScheduleConfig();
                cancelWithdrawConfigForm();
              }}
              type="primary"
              ghost
            >
              <Space>
                <MdAddCircle size={16} />
                เพิ่มตารางเวลาการใช้งานบัญชี
              </Space>
            </Button>
          </Space>
        </div>
        <TableStyled
          rowKey="id"
          rowClassName={(record) => (record.id === editingScheduleAccountId ? "active" : "")}
          dataSource={autoWithdrawAccountList}
          className="c-table header-center"
          columns={columns}
          size="small"
          locale={{
            emptyText: "ไม่พบข้อมูล",
          }}
          bordered
        />

        {(showScheduleForm || showWithdrawConfigForm) && (
          <div className="mx-auto my-4 border-2 p-4" style={{ width: 550 }}>
            {showScheduleForm && (
              <Form
                form={scheduleForm}
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 18,
                }}
                onFinish={onScheduleFormFinish}
                onValuesChange={onScheduleFormChange}
              >
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const isEdit = !!getFieldValue("isEdit");
                    const modeLabel = isEdit ? "แก้ไข" : "เพิ่ม";
                    return (
                      <div className="mb-2 text-lg underline">
                        <b>{`${modeLabel}ตารางเวลาการใช้งานบัญชี`}</b>
                      </div>
                    );
                  }}
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const isEdit = !!getFieldValue("isEdit");

                    if (isEdit) {
                      return (
                        <>
                          <Form.Item className="no-margin" label="ชื่อบัญชี" shouldUpdate>
                            {({ getFieldValue }) => {
                              return getFieldValue("accountName");
                            }}
                          </Form.Item>
                          <Form.Item label="เลขบัญชี" shouldUpdate style={{ marginBottom: 8 }}>
                            {({ getFieldValue }) => {
                              return getFieldValue("accountNo");
                            }}
                          </Form.Item>
                        </>
                      );
                    } else {
                      return (
                        <Form.Item
                          label="เลือกบัญชี"
                          name="accountId"
                          rules={[{ required: true, message: "กรุณาเลือกบัญชีที่จะทำการตั้งค่า" }]}
                        >
                          <Select>
                            {withdrawAccounts.map((account) => {
                              return (
                                <Select.Option key={account.accountId} value={account.accountId}>
                                  {account.accountNo} - {account.accountName}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      );
                    }
                  }}
                </Form.Item>
                <Form.Item name="times" label="เวลา" rules={[{ required: true, message: "กรุณาระบุเวลาที่ต้องการ" }]}>
                  <RangePicker format="HH:mm" placeholder={["ตั้งแต่เวลา", "ถึงเวลา"]} style={{ width: 200 }} />
                </Form.Item>
                <Form.Item
                  name="depositLessThanEqualAmount"
                  label="จำนวนเงิน"
                  extra="รายการถอนที่ต่ำกว่าหรือเท่ากับที่ตั้งค่า จะทำการถอนอัตโนมัติ"
                  rules={[{ required: true, message: "กรุณาระบุจำนวนเงิน" }]}
                >
                  <InputNumber step={1} style={{ width: 200 }} />
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Row>
                      <Col offset={6} span={18}>
                        <Space>
                          <Button type="primary" htmlType="submit">
                            {!!getFieldValue("isEdit") ? "บันทึก" : "เพิ่ม"}
                          </Button>
                          <Button type="primary" ghost onClick={cancelScheduleForm}>
                            ยกเลิก
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              </Form>
            )}

            {showWithdrawConfigForm && (
              <Form
                form={withdrawConfigForm}
                labelCol={{
                  span: 10,
                }}
                wrapperCol={{
                  span: 14,
                }}
                onFinish={onWithdrawConfigFormFinish}
              >
                <div className="mb-2 text-lg underline">
                  <b>ตั้งค่าการถอน</b>
                </div>
                <Form.Item name="turnOver" label="เทิร์นโอเวอร์" rules={[{ required: true, message: "กรุณาระบุ" }]}>
                  <InputNumber step={1} style={{ width: 200 }} />
                </Form.Item>
                <Form.Item
                  name="withdrawAmountPerTime"
                  label="ถอนขั้นต่ำ (ต่อครั้ง)"
                  rules={[{ required: true, message: "กรุณาระบุ" }]}
                >
                  <InputNumber step={1} style={{ width: 200 }} />
                </Form.Item>
                <Form.Item
                  name="withdrawTimePerDay"
                  label="จำนวนครั้งที่ถอนได้ (ต่อคน/ต่อวัน)"
                  rules={[{ required: true, message: "กรุณาระบุ" }]}
                >
                  <InputNumber step={1} style={{ width: 200 }} />
                </Form.Item>
                <Form.Item
                  name="limitWithdrawAmountPerDay"
                  label="ถอนได้สูงสุด (บาท/ต่อวัน)"
                  rules={[{ required: true, message: "กรุณาระบุ" }]}
                >
                  <InputNumber step={1} style={{ width: 200 }} />
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {() => (
                    <Row>
                      <Col offset={10} span={14}>
                        <Space>
                          <Button type="primary" htmlType="submit">
                            บันทึก
                          </Button>
                          <Button type="primary" ghost onClick={cancelWithdrawConfigForm}>
                            ยกเลิก
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              </Form>
            )}
          </div>
        )}
      </Spin>
    </Modal>
  );
}

export default DrawerAutoWithdrawSetup;

const TableStyled = styled<React.FC<TableProps<IAutoWithdrawAccount>>>(Table)`
  tr.active {
    background: var(--primary-color-light);
  }
`;
