function kebabToPascal(s: string) {
  return s.replace(/-([a-z])/g, (g) => g[1].toUpperCase()).replace(/^\w/, (c) => c.toUpperCase());
}

export const Color = (function getCssVars() {
  const styles = Array.from(document.styleSheets);
  const cssVars: { [key: string]: string } = {};

  styles.forEach((sheet) => {
    try {
      Array.from(sheet.cssRules || []).forEach((rule) => {
        if (rule instanceof CSSStyleRule && rule.selectorText === ":root") {
          Array.from(rule.style).forEach((styleName) => {
            if (styleName.startsWith("--") && styleName.includes("-color")) {
              const colorName = kebabToPascal(styleName.slice(2)).replace("Color", "");
              cssVars[colorName] = rule.style.getPropertyValue(styleName).trim();
            }
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
  });

  return cssVars;
})();
